















import Vue from 'vue'

export default Vue.extend({
	name: 'LoadingSpinner',
	props: {
		diameter: {
			type: Number,
			default: 40,
		},
		stroke: {
			type: Number,
			default: 4,
		},
		visible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		circleRadius(): number {
			return (this.diameter - this.stroke) / 2
		},
		circleStrokeWidth(): string {
			return `${this.stroke}px`
		},
		circleCircumference(): number {
			return 2 * Math.PI * this.circleRadius
		},
		circleStrokeDashArray(): string {
			return this.circleCircumference + 'px'
		},
		circleStrokeDashOffset(): string {
			return this.circleCircumference * 0.2 + 'px'
		}
	},
	mounted() {
		this.attachSvgStyle()
		this.attachCircleStyle()
	},
	methods: {
		attachSvgStyle(): void {
			const svg: SVGElement = this.$refs['loading-spinner__draw'] as SVGElement
			const size = `${this.diameter}px`
			svg.style.width = size
			svg.style.height = size
		},
		attachCircleStyle() {
			const circle: SVGElement = this.$refs['loading-spinner__circle'] as SVGElement
			// circle.style.strokeDashoffset = this.circleStrokeDashOffset
			circle.style.strokeDasharray = this.circleStrokeDashArray
			circle.style.strokeWidth = this.circleStrokeWidth
			circle.style.setProperty('--md-progress-spinner-start-value', String(0.95 * this.circleCircumference) )
			circle.style.setProperty('--md-progress-spinner-end-value', String(0.2 * this.circleCircumference) )
		},
	},
})
